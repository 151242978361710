<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="9"
    >
      <invoice-editable :data="invoiceData" @enable-save="canSave = true" @disable-save="canSave = false"></invoice-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :disabled="!canSave"
            @click="saveInvoice"
          >
          <v-progress-circular
            v-show="loading"
            :size="20"
            :width="2"
            color="#fff"
            indeterminate>
          </v-progress-circular>
          <v-icon
            class="me-2"
            left
            v-show="!loading"
          >
            {{ icons.mdiDatabasePlus }}
          </v-icon>
          <span v-show="!loading">Save</span>
          </v-btn>
        </v-card-text>
      </v-card>

      <invoice-sidebar-settings :data="invoiceData"></invoice-sidebar-settings>

    </v-col>
  </v-row>
</template>

<script>
import { mdiSendOutline, mdiDatabasePlus } from '@mdi/js'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceEditable from '../InvoiceEditable.vue'
import InvoiceSidebarSettings from '../InvoiceSidebarSettings.vue'

export default {
  components: {
    InvoiceEditable,
    InvoiceSidebarSettings
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const canSave = ref(false)
    const loading = ref(false)

    const invoiceData = ref({
      invoiceData: {
        invoice_number: 0,
        invoice_year: moment().format("YYYY"),
        issuer: "SP",
        lang: "SI",
        paid: 0,
        currency: "EUR",
        billing_place: "Ljubljana",
        billing_date: moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD'),
        delivery_date: moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD'),
        due_date: moment().subtract(1,'months').endOf('month').add(15,'days').format('YYYY-MM-DD'),
        customer: {},
        line_items: [
          {
            label: '',
            unit_price: 0,
            quantity: 0,
            vat_rate: 0
          },
        ],
      }
    })

    store
      .dispatch('app-invoice/fetchNewInvoiceNumber')
      .then(response => {
        invoiceData.value.invoiceData.invoice_number = response.data

      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const saveInvoice = () => {
      store
        .dispatch('app-invoice/addInvoice', invoiceData.value.invoiceData)
        .then(response => {
          store.dispatch("addNotification",{message:"Invoice was saved.",type: "success"});
          router.push({ name: 'apps-invoice-edit', params: { id: response.data.id } })
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      invoiceData,
      canSave,
      loading,
      saveInvoice,
      // Icons
      icons: {
        mdiSendOutline,
        mdiDatabasePlus
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
